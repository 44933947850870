import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from "moment-timezone";
import * as _ from "lodash";

@Injectable({
    providedIn: 'root'
})
export class ReadingsService {

    constructor() { }

    prepareReadings(readings, graphType) {
        // console.log('Readings', readings);

        let reverseFlow = false;
        let readingsOrdered = [];
        if (readings.length > 0) {
            if (readings[0].value < readings[readings.length - 1].value) {
                reverseFlow = true;
            }
            readingsOrdered = _.orderBy(readings, ["timestamp"], ["desc"]);
            // readingsOrdered.reverse();
            // console.log('reverseFlow', reverseFlow);
            readingsOrdered.forEach((reading, index) => {
                if (reading.value === null) {
                    console.log('value: ', reading);
                };
                let timezone = moment.tz('Africa/Johannesburg').utcOffset();
                reading.timestamp = moment.utc(reading.timestamp).add(timezone, "minutes").format("YYYY-MM-DD HH:mm:ss");
                // console.log('reading: ', reading.value);
                reading.value = parseFloat(reading.value.toFixed(3));
                if (graphType != 'Line') {
                    if (index != 0) {
                        if (!reverseFlow) {
                            reading.usage = parseFloat((reading.value * 1000 - readingsOrdered[index - 1].value * 1000).toFixed(2));
                        } else {
                            reading.usage = parseFloat((readingsOrdered[index - 1].value * 1000 - reading.value * 1000).toFixed(2));
                        }
                    }
                } else {
                    reading.usage = reading.value;
                }
            });
            readingsOrdered[0].usage = 'OPENING READING';
        }
        return {
            readings: readingsOrdered,
            reverseFlow,
        };
    }


    calcHourlyUsage(data, daySelection, reverseFlow) {

        let day = moment().format('YYYY-MM-DD');
        if (daySelection) {
            day = daySelection;
        }
        data = _.orderBy(data, ['timestamp'], ['asc']);
        var Midnight = new Date();
        Midnight.setHours(0, 0, 0, 1);
        Midnight.setHours(Midnight.getHours());

        const groupedDayHour = _(data)
            .map(item => {
                return {
                    dateHour: moment.utc(item.timestamp).format('YYYY-MM-DD HH'),
                    date: moment.utc(item.timestamp).format('YYYY-MM-DD'),
                    id: item.id,
                    timestamp: moment.utc(item.timestamp).format('YYYY-MM-DD HH:mm:ss'),
                    value: item.value
                };
            })
            .groupBy('dateHour')
            .mapValues(item => {
                return _.maxBy(item, 'id');
            })
            .groupBy('date')
            .value();


        // Adds the last entry from the previous date reading (most of the time the previous day) to the start of the current day.  Uses this as a start point for usage calcs
        let i = 0;
        var dayKeys = Object.keys(groupedDayHour).sort();
        for (let key in groupedDayHour) {
            if (i > 0) {
                groupedDayHour[key].unshift(groupedDayHour[dayKeys[i - 1]][groupedDayHour[dayKeys[i - 1]].length - 1]);
            }
            i++;
        }

        if (groupedDayHour[day]) {
            groupedDayHour[day].forEach((row: any, index) => {
                if (index > 0) {
                    if (!reverseFlow) {
                        row.usage = Math.round((row.value - groupedDayHour[day][index - 1].value) * 1000);  //TODO - Factor in KL conversion
                    } else {
                        row.usage = Math.round((groupedDayHour[day][index - 1].value - row.value) * 1000);
                    }
                }
            });
            groupedDayHour[day].shift();
        } else {
            groupedDayHour[day] = [];
        }

        let labelsHourly = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
        let usageHourly = [];

        let factor = 0;
        labelsHourly.forEach((hour: any, index) => {
            if ((groupedDayHour[day][index - factor])) {
                if (groupedDayHour[day][index - factor].dateHour.split(' ')[1] === hour) {
                    usageHourly.push(groupedDayHour[day][index - factor]['usage']);
                } else {
                    usageHourly.push(0);
                    factor++;
                }
            } else {
                usageHourly.push(0);
                factor++;
            }
        });
        return ({ labelsHourly, usageHourly });
    }

    calcMonthlyUsage(data, reverseFlow) {
        var Midnight = new Date();
        Midnight.setHours(0, 0, 0, 1);
        Midnight.setHours(Midnight.getHours());

        const groupedDay = _(data)
            .map(item => {
                return {
                    date: moment.utc(item.timestamp).format('YYYY-MM-DD'),
                    id: item.id,
                    timestamp: moment.utc(item.timestamp).format('YYYY-MM-DD HH:mm:ss'),
                    value: item.value
                };
            })
            .orderBy('date')
            .groupBy('date')
            .mapValues(item => {
                return _.maxBy(item, 'id');
            })
            .map()
            .value();

        groupedDay.forEach((row: any, index) => {
            if (index > 0) {
                if (!reverseFlow) {
                    row.usage = Math.round((row.value - groupedDay[index - 1].value) * 1000);
                } else {
                    row.usage = Math.round((groupedDay[index - 1].value - row.value) * 1000);
                }
            }
        });
        groupedDay.shift();

        let labelsMonthly = [];
        let usageMonthly = [];

        groupedDay.forEach((row: any) => {
            labelsMonthly.push(moment(row.date).format('DD-MM'));
            usageMonthly.push(row.usage);
        });

        return ({ labelsMonthly, usageMonthly });
    }
}