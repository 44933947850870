import { DeviceModel } from "../models/devices.model";

export class ShouldGetDevices {
    static readonly type = '[Device] Should Get Devices';
    constructor(public payload: boolean) { }
}

export class LoadDevices {
    static readonly type = '[Device] Load Devices';
    constructor() { }
}

export class RedrawDashGraphs {
    static readonly type = '[UI] Redraw Dash Graphs';
    constructor(public payload: boolean) { }
}


export class SetCurrentMeterId {
    static readonly type = '[UI] Set Current MeterId';
    constructor(public payload: number) { }
}

export class SetPrimaryDevice {
    static readonly type = '[UI] Set Primary Device';
    constructor(public payload: number) { };
}

export class UpdateDevice {
    static readonly type = '[Device] Update Device';
    constructor(public payload: any) { }
}

export class CalcHourlyData {
    static readonly type = '[Device] Calc Hourly Data';
    constructor(public payload: any) { }

}

export class SetSettings {
    static readonly type = '[Device] Set Upper Limit';
    constructor(public updateData: any, public settingExists: boolean) { }
}

export class UpdateCurrentDay {
    static readonly type = '[Device] Update Current Day';
    constructor(public payload: any) { }

}