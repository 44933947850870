import { Injectable, NgZone } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { ApiService } from 'src/app/services/api.service';
import { ReadingsService } from 'src/app/services/readings.service';
import { CalcHourlyData, LoadDevices, RedrawDashGraphs, SetCurrentMeterId, SetPrimaryDevice, SetSettings, ShouldGetDevices, UpdateCurrentDay, UpdateDevice } from '../actions/devices.action';
import { AlertModel, DeviceModel, DevicesStateModel } from '../models/devices.model';
import { AppState } from './app.state';
import * as moment from 'moment';
import { LoadDeviceDetail, SetCurrentDevice, UpdateDeviceDetail } from '../actions/deviceDetail.action';
import { Navigate } from '@ngxs/router-plugin';
import { from, of } from 'rxjs';
import { GetNotifications } from '../actions/app.actions';



// type DevicesStateModel = DevicesMap;
// type LocalStateModel = DevicesStateModel;
// type LocalStateContext = StateContext<DevicesStateModel>;

@State<DevicesStateModel>({
    name: 'devicesState',
    defaults: {
        locations: [],
        devices: [],
        redrawDashGraphs: false,
        deviceCount: null,
        currentMeterId: null,
        primaryDevice: null,
        currentDay: moment().format('DD-MM-YYYY')
    }
})


@Injectable()
export class DevicesState {
    constructor(private zone: NgZone,
        private store: Store,
        private readingsService: ReadingsService,
        private apiService: ApiService
    ) { }


    @Action(ShouldGetDevices)
    shouldGetDevices({ patchState, dispatch, setState, getState, }: StateContext<DevicesStateModel>, { payload }: ShouldGetDevices) {
        const state = getState();
        if (state.devices.length === 0 || payload === true) {
            dispatch(new LoadDevices());
            // dispatch(new LoadDeviceDetail(devices));
        }
    }

    @Action(LoadDevices)
    async loadDevices({ patchState, dispatch, getState }: StateContext<DevicesStateModel>) {

        dispatch(new GetNotifications());
        let devices: any = await this.apiService.getDevices();
        if (devices.length === 0) {

            dispatch(new Navigate(['protected/register-unit']));
            return;
        }
        let locations = [...new Set<string>(devices.map(item => item.LocationName))];
        patchState({ locations, deviceCount: devices.length });

        //** After loading the skelaton get device details **//

        let primaryId: number;
        const state = getState();
        primaryId = state.primaryDevice;
        if (primaryId === null) {
            for (let i = 0; i < devices.length; i++) {
                if (devices[i].MeterType === 'Electricity') {
                    primaryId = devices[i].MeterId;
                }
            }
        }


        // for (let device of devices) {


        // dispatch(new UpdateDevice({
        //     MeterId: device.MeterId,
        //     readings: readingsWithUsage.readings,
        //     reverseFlow: readingsWithUsage.reverseFlow,
        //     hourlyData: hourlyData,
        //     monthlyData: monthlyData,
        //     deviceSettings: settings
        // }));
        // dispatch(new UpdateDeviceDetail({
        //     MeterId: device.MeterId,
        //     readings: readingsWithUsage.readings,
        //     reverseFlow: readingsWithUsage.reverseFlow,
        //     hourlyData: hourlyData,
        //     monthlyData: monthlyData,
        //     deviceSettings: alerts
        // }));
        // });

        // };



        const devicesData = await Promise.all(devices.map(device => this.getDeviceData(device)));
        patchState({ devices: devicesData, primaryDevice: primaryId, currentMeterId: primaryId });

    }

    async getDeviceData(device) {
        const [settings, readings] = await Promise.all([this.apiService.getSettings(device.MeterId), this.apiService.getReadings(device.MeterId)]);
        const readingsWithUsage = this.readingsService.prepareReadings(readings, device.GraphType);
        const hourlyData = this.readingsService.calcHourlyUsage(readings, moment().format('YYYY-MM-DD'), readingsWithUsage.reverseFlow);
        const monthlyData = this.readingsService.calcMonthlyUsage(readings, readingsWithUsage.reverseFlow);
        device.readings = readingsWithUsage.readings;
        device.reverseFlow = readingsWithUsage.reverseFlow;
        device.hourlyData = hourlyData;
        device.monthlyData = monthlyData;
        device.Settings = JSON.parse(device.Settings);
        device.deviceSettings = settings;
        return device;
    }

    @Selector()
    static deviceCount(state: DevicesStateModel) {
        return state.deviceCount;
    }

    @Action(SetCurrentMeterId)
    setCurrentMeterId({ patchState, getState }: StateContext<DevicesStateModel>, { payload }: any) {
        const state = getState();
        const currentDevice = state.devices.find(device => device.MeterId === payload);
        //console.log('currentDevice :>> ', currentDevice);
        this.store.dispatch(new SetCurrentDevice(currentDevice));
        patchState({ currentMeterId: payload });
    }

    @Selector()
    static getCurrentMeterId(state: DevicesStateModel) {
        return state.currentMeterId;
    }

    @Action(SetPrimaryDevice)
    async setPrimaryDevice({ patchState }: StateContext<DevicesStateModel>, { payload }: any) {
        patchState({ primaryDevice: payload });
    }

    @Selector()
    static getPrimaryDevice(state: DevicesStateModel) {
        return state.primaryDevice;
    }


    @Selector()
    static getLocations(state: DevicesStateModel) {
        return state.locations;
    }

    @Selector()
    static getFilteredDevicesFn(devicesStateModel: DevicesStateModel) {
        return (locationName: string) => {
            return devicesStateModel.devices.filter(device => device.LocationName === locationName);
        };
    }

    @Selector()
    static getFilteredDeviceFn(devicesStateModel: DevicesStateModel) {
        return (MeterId: number) => {
            return devicesStateModel.devices.filter(device => device.MeterId === MeterId)[0];
        };
    }

    @Selector()
    static getById(devicesStateModel: DevicesStateModel) {
        return (MeterId: number) => {
            return devicesStateModel.devices.find(device => device.MeterId === MeterId);
        };
    }

    @Action(UpdateDevice)
    updateDevice(ctx: StateContext<DevicesStateModel>, { payload }: any): void {
        ctx.setState(
            patch({
                devices: updateItem<DeviceModel>(device => device.MeterId === payload.MeterId,
                    patch(payload))
            })
        );
    }

    @Action(CalcHourlyData)
    calcHourlyData({ patchState, dispatch, getState }: StateContext<DevicesStateModel>, { payload }: any): void {
        const state = getState();
        let currentMeterId = state.currentMeterId;
        let device = getState().devices.filter(device => device.MeterId === currentMeterId)[0];
        const hourlyData = this.readingsService.calcHourlyUsage(device.readings, moment(payload.date, 'DD-MM-YYYY').format('YYYY-MM-DD'), device.reverseFlow);
        patchState({ currentDay: payload.date });
        dispatch(new UpdateDevice({
            MeterId: device.MeterId,
            hourlyData: hourlyData
        }));

    }

    @Action(SetSettings)
    async setUpperLimit({ getState, patchState, setState }: StateContext<DevicesStateModel>, { updateData, settingExists }: any) {
        let state = getState();
        let newStateData = state.devices;
        let saved: any;
        if (settingExists === true) {
            saved = await this.apiService.setSetting(updateData.id, updateData.typeId, updateData.value, updateData.emailNotification);
        } else {
            saved = await this.apiService.createSetting(updateData.typeId, updateData.value, updateData.emailNotification, updateData.userLocationId, updateData.meterId, updateData.active);
            //console.log('CREATE NEW SETTING FIRED');
        }
        //console.log('CHECK RETURN', saved);
        if (saved === "update successful") {

            for (let i = 0; i < state.devices.length; i++) {
                for (let j = 0; j < state.devices[i].deviceSettings.length; j++) {
                    if (state.devices[i].deviceSettings[j].Id === updateData.id) {
                        newStateData[i].deviceSettings[j].AlertValue = updateData.value;
                        newStateData[i].deviceSettings[j].PushNotification = updateData.pushNotification;
                        newStateData[i].deviceSettings[j].EmailNotification = updateData.emailNotification;
                    }
                }
            }
            patchState({ devices: newStateData });
        } else {

            for (let i = 0; i < state.devices.length; i++) {
                if (updateData.meterId === state.devices[i].MeterId) {
                    let newSetting = {
                        UserLocationId: updateData.userLocationId,
                        AlertValue: updateData.value,
                        PushNotification: updateData.pushNotification,
                        EmailNotification: updateData.emailNotification,
                        AlertType: "UpperLimit",
                        Id: saved.Id
                    };
                    newStateData[i].deviceSettings.push(newSetting);
                    //console.log('ADD NEW SETTING TO STATE FIRED');
                }
            }
            patchState({ devices: newStateData });
        }
        return saved;
    }


    @Action(UpdateCurrentDay)
    updateCurrentDay({ patchState }: StateContext<DevicesStateModel>, { payload }: any): void {
        patchState({ currentDay: moment(payload).format('DD-MM-YYYY') });
    }

    @Selector()
    static getCurrentDay(state: DevicesStateModel) {
        return state.currentDay;
    }

    @Action(RedrawDashGraphs)
    redrawDashGraphs({ patchState, getState }: StateContext<DevicesStateModel>, { payload }: RedrawDashGraphs) {
        patchState({ redrawDashGraphs: payload });
    }

    @Selector()
    static getRedrawDashGraphs(state: DevicesStateModel) {
        return state.redrawDashGraphs;
    }

}