import { State, Action, StateContext, Selector, Select } from '@ngxs/store';
import { patch, removeItem, updateItem } from "@ngxs/store/operators";



import { Injectable, NgZone } from '@angular/core';
import { AppStateModel, NotificationModel, UserAuthModel } from '../models/app.models';
import {
    AuthLogout,
    AuthUser, DeleteUser, GetDeviceGraph, GetNotifications, GetOzowHash, GetTransactionHistory, GetWalletId, LoadCurrentWalletBalance, LoadDashGraphs, RegisterUnit, SetCameraActive, SetDeviceType, SetFailedAuth, SetLoadDeviceGraph
    , SetNotificationFlag, SetPage, SetShowSpinner, SetShowWalletSpinner, SetWalletTopupStatus, WalletTopUp
} from '../actions/app.actions';
import { Auth } from 'aws-amplify';
import { Navigate } from '@ngxs/router-plugin';
import { ApiService } from 'src/app/services/api.service';
import produce from 'immer';
import { LoadDevices, UpdateDevice } from '../actions/devices.action';
import { ReadingsService } from 'src/app/services/readings.service';
import * as moment from 'moment';
import { dispatch } from 'rxjs/internal/observable/pairs';
import { selectorOptionsMetaAccessor } from '@ngxs/store/src/utils/selector-utils';
import { LoadDeviceDetail, UpdateDeviceDetail } from '../actions/deviceDetail.action';
import { StateResetAll } from 'ngxs-reset-plugin';
import { ToasterService } from 'src/app/utils/toaster.service';
import { LocalStorage } from 'src/app/utils/storage';



@State<AppStateModel>({
    name: 'app',
    defaults: {
        version: '1.5.0',
        showSpinner: false,
        cameraActive: false,
        showWalletSpinner: false,
        failedAuth: false,
        selectedPage: 'Dashboard',
        loadDeviceGraph: false,
        graphsLoaded: false,
        redrawDashGraphs: false,
        deviceType: 'water',
        loadDashGraphs: false,
        walletId: null,
        walletBalance: 0,
        walletTopupStatus: '',
        notifications: null,
        unreadNotifications: null,
        transactionHistory: null,
        ozowPayload: {}
    }
})




@Injectable()
export class AppState {
    constructor(private zone: NgZone,
        private apiService: ApiService,
        private readingsService: ReadingsService,
        private toasterService: ToasterService,
        private storageService: LocalStorage
    ) { }

    @Selector()
    static getVersion(state: AppStateModel) {
        return state.version;
    }

    @Action(SetShowSpinner)
    setShowSpinner({ patchState }: StateContext<AppStateModel>, { payload }: any) {
        patchState({ showSpinner: payload });
    }


    @Action(SetShowWalletSpinner)
    setShowWalletSpinner({ patchState }: StateContext<AppStateModel>, { payload }: any) {
        patchState({ showWalletSpinner: payload });
    }

    @Selector()
    static getShowSpinner(state: AppStateModel) {
        return state.showSpinner;
    }

    @Selector()
    static getShowWalletSpinner(state: AppStateModel) {
        return state.showWalletSpinner;
    }

    @Action(SetCameraActive)
    setCameraActive({ patchState }: StateContext<AppStateModel>, { payload }: any) {
        patchState({ cameraActive: payload });
    }

    @Selector()
    static getCameraActive(state: AppStateModel) {
        return state.cameraActive;
    }


    @Action(AuthLogout)
    authLogout({ dispatch, patchState }: StateContext<AppStateModel>) {
        patchState({ showSpinner: true });
        dispatch(new StateResetAll());
        dispatch(new Navigate(['/auth']));
        setTimeout(() => {
            patchState({ showSpinner: false });

            window.location.reload();
        }, 100);
    }

    @Action(AuthUser)
    authUser({ patchState, dispatch, setState, getState, }: StateContext<AppStateModel>, { payload }: AuthUser) {
        patchState({ showSpinner: true });

        let { email, password } = payload;
        Auth.signIn(email, password).then(async (authResult) => {
            //console.log(authResult);
            dispatch(new GetWalletId()).subscribe(() => {
                dispatch(new Navigate(['protected']));

                patchState({ showSpinner: false });
            });
        }).catch((error) => {
            //console.log(error);
            patchState({ failedAuth: true, showSpinner: false });

        });
    }

    @Action(DeleteUser)
    async DeleteUser({ patchState, dispatch, getState, }: StateContext<AppStateModel>, { payload }: DeleteUser) {
        try {
            patchState({ showSpinner: true });

            if (payload) await this.apiService.deleteUser();
            await Auth.deleteUser();
            this.toasterService.presentToast('top', 'User Account removed successfully. You can uninstall the app or sign up with a new account.', false, 'success', 'Account', 3000);
            this.storageService.removeValue('netqSystems-userData');

            setTimeout(() => {
                patchState({ showSpinner: true });
                dispatch(new AuthLogout());
            }, 3000);

        } catch (error) {
            console.log('Error deleting user', error);
            this.toasterService.presentToast('top', error.error, false, 'danger', 'ERROR', 2000);

        }
    }

    @Action(RegisterUnit)
    async registerUnit({ patchState, dispatch, setState, getState, }: StateContext<AppStateModel>, { payload }: any) {
        try {
            let result: any = await this.apiService.registerInitialLocation({ token: payload });
            if (result.success) {
                dispatch(new GetWalletId()).subscribe(() => {
                    dispatch(new Navigate(['protected']));
                    patchState({ showSpinner: false });
                });
            }
        } catch (error) {
            console.log(error);
            patchState({ showSpinner: false, cameraActive: false });

            if (typeof error.error === 'string') this.toasterService.presentToast('top', error.error, false, 'danger', 'ERROR', 5000);


        }
    }



    @Action(GetWalletId)
    async getWalletId({ patchState, dispatch, setState, getState, }: StateContext<AppStateModel>, { payload }: any) {
        try {
            let walletResult: any = await this.apiService.getWalletId();
            if (walletResult.success) {
                patchState({ walletId: walletResult.data.WalletId });

            }

        } catch (error) {

        }
    }




    // @Action(GetDevices)
    // async getDevices({ patchState, dispatch, setState, getState, }: StateContext<AppStateModel>) {
    //     let devices: any = await this.apiService.getDevices();
    //     for (let i = 0; i < devices.length; i++) {
    //         let alerts = await this.apiService.getSettings(devices[i].MeterId);
    //         devices[i].deviceSettings = alerts;
    //     }
    //     dispatch(new LoadDevices());
    //     dispatch(new LoadDeviceDetail(devices));
    //     if (devices.length > 0) {
    //         let primaryId: number;
    //         const state = getState();
    //         primaryId = state.primaryDevice;
    //         if (primaryId === null) {
    //             for (let i = 0; i < devices.length; i++) {
    //                 if (devices[i].MeterType === 'Electricity') {
    //                     primaryId = devices[i].MeterId;
    //                 }
    //             }
    //         }
    //         patchState({ currentMeterId: primaryId });
    //         devices.forEach(device => {
    //             this.apiService.getReadings(device.MeterId).then(readings => {
    //                 const readingsWithUsage = this.readingsService.prepareReadings(readings, device.GraphType);
    //                 const hourlyData = this.readingsService.calcHourlyUsage(readings, moment().format('YYYY-MM-DD'), readingsWithUsage.reverseFlow);
    //                 const monthlyData = this.readingsService.calcMonthlyUsage(readings, readingsWithUsage.reverseFlow);
    //                 dispatch(new UpdateDevice({
    //                     MeterId: device.MeterId,
    //                     readings: readingsWithUsage.readings,
    //                     reverseFlow: readingsWithUsage.reverseFlow,
    //                     hourlyData: hourlyData,
    //                     monthlyData: monthlyData
    //                 }));
    //                 dispatch(new UpdateDeviceDetail({
    //                     MeterId: device.MeterId,
    //                     readings: readingsWithUsage.readings,
    //                     reverseFlow: readingsWithUsage.reverseFlow,
    //                     hourlyData: hourlyData,
    //                     monthlyData: monthlyData
    //                 }));
    //             });

    //         });

    //     } else {
    //         dispatch(new Navigate(['protected/register-unit']));
    //     }
    // }

    @Selector()
    static getLocations(state: AppStateModel) {
        return;
    }

    @Action(SetFailedAuth)
    setFailedAuth({ patchState }: StateContext<AppStateModel>, { payload }: any) {
        patchState({ failedAuth: payload });
    }

    @Selector()
    static getFailedAuth(state: AppStateModel) {
        return state.failedAuth;
    }


    @Action(SetPage)
    setPage({ patchState }: StateContext<AppStateModel>, { payload }: any) {
        let page;
        switch (payload) {
            case 'dashboard':
                page = 'Dashboard';
                break;
            case 'device-management':
                page = "Device Details";
                break;
            case 'location-management':
                page = 'Locations';
                break;
            case 'wallet-management':
                page = 'Wallet';
                break;
            case 'settings-management':
                page = 'Settings';
                break;
        }
        patchState({ selectedPage: page });
    }

    @Selector()
    static getPage(state: AppStateModel) {
        return state.selectedPage;
    }


    @Action(GetDeviceGraph)
    getDeviceGraph({ patchState }: StateContext<AppStateModel>, { payload }: any) {
        patchState({ loadDeviceGraph: true });
    }


    @Action(SetLoadDeviceGraph)
    setLoadDeviceGraph({ patchState }: StateContext<AppStateModel>, { payload }: any) {
        patchState({ loadDeviceGraph: payload });
    }


    @Selector()
    static loadDeviceGraph(state: AppStateModel) {
        return state.loadDeviceGraph;
    }

    @Action(SetDeviceType)
    setDeviceType({ patchState }: StateContext<AppStateModel>, { payload }: any) {
        patchState({ deviceType: payload });
    }

    @Selector()
    static getDeviceType(state: AppStateModel) {
        return state.deviceType;
    }

    @Action(LoadDashGraphs)
    loadDashGraphs({ patchState }: StateContext<AppStateModel>, { payload }: any) {
        //console.log('setting dash graphs', payload);
        patchState({ loadDashGraphs: payload });
    }

    @Selector()
    static getLoadDashGraphs(state: AppStateModel) {
        return state.loadDashGraphs;
    }

    @Action(LoadCurrentWalletBalance)
    async loadCurrentWalletBalance({ patchState, getState, dispatch }: StateContext<AppStateModel>) {
        const state = getState();
        dispatch(new SetShowWalletSpinner(true));

        //console.log('state.walletId :>> ', state.walletId);
        if (state.walletId == null) {
            let walletResult: any = await this.apiService.getWalletId();
            //console.log('walletResult :>> ', walletResult);
            if (walletResult.success) {
                await patchState({ walletId: walletResult.data.WalletId });
                let result: any = await this.apiService.getCurrentWalletBalance(walletResult.data.WalletId);
                //console.log('Result getting wallet 1', result);
                await patchState({ walletBalance: result.currentBalance });
                dispatch(new SetShowWalletSpinner(false));

            }
        } else {
            let result: any = await this.apiService.getCurrentWalletBalance(state.walletId);
            //console.log('Result getting wallet 2', result);
            await patchState({ walletBalance: result.currentBalance });
            await patchState({ showWalletSpinner: false });

        }


    }

    @Selector()
    static getWallet(state: AppStateModel) {
        return state.walletBalance;
    }


    @Action(WalletTopUp)
    async walletTopUp({ patchState, dispatch }: StateContext<AppStateModel>, { payload }: any) {
        // patchState({ walletTopupStatus: 'pending' });
        dispatch(new SetWalletTopupStatus('pending'));
        let result: any = await this.apiService.walletTopUp(payload);
        //console.log('Result', result);
        patchState({ walletBalance: result.currentBalance });
        patchState({ showSpinner: false });
        dispatch(new SetWalletTopupStatus('successful'));
        // patchState({ walletTopupStatus: 'successful' });
    }

    @Action(GetOzowHash)
    async getOzowHash({ patchState, dispatch }: StateContext<AppStateModel>, { payload }: GetOzowHash) {
        try {
            let result: any = await this.apiService.getOzowHash({ amount: payload });
            //console.log('resultHash :>> ', result);
            patchState({ ozowPayload: result });
            // spinner for ozow iframe
            patchState({ showSpinner: false });
        } catch (error) {
            //console.log('error :>> ', error);
        }
    }

    @Selector()
    static getOzowPayload(state: AppStateModel) {
        return state.ozowPayload;
    }

    @Selector()
    static getWalletTopupStatus(state: AppStateModel) {
        return state.walletTopupStatus;
    }


    @Action(SetWalletTopupStatus)
    async setWalletTopupStatus({ patchState }: StateContext<AppStateModel>, { payload }: any) {
        patchState({ walletTopupStatus: payload });
    }

    @Action(GetNotifications)
    async getNotifications({ patchState }: StateContext<AppStateModel>) {
        try {
            patchState({ showSpinner: true });
            let result: any = await this.apiService.getNotifications();

            patchState({
                notifications: result.sort((a, b) => (a.SentTimestamp > b.SentTimestamp) ? -1 : ((b.SentTimestamp > a.SentTimestamp) ? 1 : 0)),
                unreadNotifications: result.filter(notification => !notification.ReadTimestamp).length
            });
            patchState({ showSpinner: false });
            return 'Success';
        } catch (error) {
            patchState({ notifications: [] });

            patchState({ showSpinner: false });
        }
    }

    @Selector()
    static showNotifications(state: AppStateModel) {
        return state.notifications;
    }

    @Selector()
    static getUnreadNotificationCount(state: AppStateModel) {
        return state.unreadNotifications;
    }

    @Action(SetNotificationFlag)
    async setNotificationFlag({ patchState, setState, getState }: StateContext<AppStateModel>, { payload }: any) {
        const state = getState();
        if (payload.flag === 'Deleted') {
            setState(
                patch({ notifications: removeItem<NotificationModel>(payload.index) }),
            );
        }
        if (!state.notifications[payload.index].ReadTimestamp) {
            let result = await this.apiService.setNotificationFlag(payload.id, payload.flag);
            if (payload.flag === 'Read') {
                setState(
                    patch({
                        notifications: updateItem<NotificationModel>(payload.index, result[0]), result,
                        unreadNotifications: (state.unreadNotifications - 1) >= 0 ? state.unreadNotifications - 1 : 0
                    }),
                );
            }
        }
        return 'success';
    }

    @Action(GetTransactionHistory)
    async getTransactionHistory({ patchState, getState }: StateContext<AppStateModel>) {
        const state = getState();
        let result: any;
        patchState({ showSpinner: true });
        if (state.walletId == null) {
            let walletResult: any = await this.apiService.getWalletId();
            //console.log('walletResult :>> ', walletResult);
            if (walletResult.success) {
                await patchState({ walletId: walletResult.data.WalletId });
                result = await this.apiService.getWalletTransactions(walletResult.data.WalletId);
            }
        } else {
            result = await this.apiService.getWalletTransactions(state.walletId);
        }

        patchState({ transactionHistory: result });
        patchState({ showSpinner: false });
        return result;
    }

    @Selector()
    static showTransactionHistory(state: AppStateModel) {
        return state.transactionHistory;
    }




}
