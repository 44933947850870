import { AES, enc } from 'crypto-js';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocalStorage {

    constructor() { }

    setValue(key, value) {
        let encryptedStorage = AES.encrypt(JSON.stringify(value), environment.secretKey);
        return localStorage.setItem(key, encryptedStorage.toString());

    }

    getValue(key) {
        let storage = localStorage.getItem(key);
        if (!storage) return;
        let decrypted = AES.decrypt(storage, environment.secretKey);
        return JSON.parse(decrypted.toString(enc.Utf8));
    }

    removeValue(key) {
        localStorage.removeItem(key);
    }
}
