//httpConfig.interceptor.ts
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

    constructor() { }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let token;
        return from(Auth.currentSession())
            .pipe(
                switchMap((res: any) => {
                    token = res.getIdToken().getJwtToken();

                    //Authentication by setting header with token value
                    if (token) {
                        request = request.clone({
                            setHeaders: {
                                'Authorization': token
                            }
                        });
                    }

                    if (!request.headers.has('Content-Type')) {
                        request = request.clone({
                            setHeaders: {
                                'content-type': 'application/json'
                            }
                        });
                    }

                    request = request.clone({
                        headers: request.headers.set('Accept', 'application/json')
                    });

                    return next.handle(request).pipe(
                        map((event: HttpEvent<any>) => {
                            if (event instanceof HttpResponse) {
                                // console.log('event--->>>', event);
                            }
                            return event;
                        }),
                        catchError((error: HttpErrorResponse) => {
                            console.error(error);
                            return throwError(error);
                        }));
                }));


    }


}