import { Injectable, NgZone } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { ApiService } from 'src/app/services/api.service';
import { ReadingsService } from 'src/app/services/readings.service';
import { AppState } from './app.state';
import * as moment from 'moment';
import { DeviceDetailStateModel, DeviceModel, } from '../models/deviceDetail.model';
import { CalcDetailHourlyData, LoadDeviceDetail, SetCurrentDevice, UpdateCurrentDay, UpdateDeviceDetail, UpdateReadings } from '../actions/deviceDetail.action';
import { DevicesState } from './devices.state';
import { SetShowSpinner } from '../actions/app.actions';



// type DevicesStateModel = DevicesMap;
// type LocalStateModel = DevicesStateModel;
// type LocalStateContext = StateContext<DevicesStateModel>;

@State<DeviceDetailStateModel>({
    name: 'deviceDetailState',
    defaults: {
        locations: [],
        devices: [],
        currentDay: moment().format('DD-MM-YYYY'),
        device: null
    }
})


@Injectable()
export class DeviceDetailState {
    constructor(private zone: NgZone,
        private store: Store,
        private readingsService: ReadingsService,
        private apiService: ApiService
    ) { }

    @Action(SetCurrentDevice)
    setCurrentDevice({ dispatch, getState, patchState }: StateContext<DeviceDetailStateModel>, { payload }: SetCurrentDevice) {
        patchState({ device: payload });
    }

    @Selector()
    static getCurrentDevice(state: DeviceDetailStateModel) {
        return state.device;
    }

    @Action(LoadDeviceDetail)
    loadDevices(ctx: StateContext<DeviceDetailStateModel>, { payload }: any): void {
        let locations = [...new Set<string>(payload.map(item => item.LocationName))];
        let day = moment().format('DD-MM-YYYY');
        ctx.patchState({ locations, devices: payload, currentDay: day });
    }

    @Selector()
    static getLocations(state: DeviceDetailStateModel) {
        return state.locations;
    }

    @Selector()
    static getFilteredDevicesFn(deviceDetailStateModel: DeviceDetailStateModel) {
        return (locationName: string) => {
            return deviceDetailStateModel.devices.filter(device => device.LocationName === locationName);
        };
    }

    @Selector()
    static getFilteredDeviceFn(deviceDetailStateModel: DeviceDetailStateModel) {
        return (MeterId: number) => {
            return deviceDetailStateModel.devices.filter(device => device.MeterId === MeterId)[0];
        };
    }

    @Selector()
    static getCurrentDay(state: DeviceDetailStateModel) {
        return state.currentDay;
    }

    @Action(UpdateDeviceDetail)
    updateDevice(ctx: StateContext<DeviceDetailStateModel>, { payload }: any): void {
        ctx.setState(
            patch({
                devices: updateItem<DeviceModel>(device => device.MeterId === payload.MeterId,
                    patch(payload))
            })
        );
    }

    @Action(CalcDetailHourlyData)
    calcDetailHourlyData({ dispatch, getState, patchState, setState }: StateContext<DeviceDetailStateModel>, { payload }: any) {

        dispatch(new UpdateCurrentDay(moment(payload.date, 'DD-MM-YYYY').format('YYYY-MM-DD')));
        const state = getState();
        let device = { ...state.device };
        const hourlyData = this.readingsService.calcHourlyUsage(device.readings, moment(payload.date, 'DD-MM-YYYY').format('YYYY-MM-DD'), device.reverseFlow);

        device.hourlyData = hourlyData;
        // return setState({ ...state, ...JSON.parse(JSON.stringify(device)) });
        patchState({ device: device });


    }

    @Action(UpdateCurrentDay)
    updateCurrentDay({ patchState }: StateContext<DeviceDetailStateModel>, { payload }: any): void {
        patchState({ currentDay: moment(payload).format('DD-MM-YYYY') });
    }

    @Action(UpdateReadings)
    updateReadings({ patchState, getState, setState }: StateContext<DeviceDetailStateModel>, { payload }: UpdateReadings) {
        const state = getState();
        let device = { ...state.device };
        device.readings = payload.readings;
        device.hourlyData = payload.hourlyData;
        device.monthlyData = payload.monthlyData;
        //console.log('device :>> ', device);
        // return setState({ ...state, ...JSON.parse(JSON.stringify(device)) });
        patchState({ device: device });


    }


}