import { Injectable } from "@angular/core";
import { ToastController, ToastOptions } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class ToasterService {
    constructor(
        private toastController: ToastController
    ) { }

    async presentToast(position: 'top' | 'middle' | 'bottom', message, acknowledgement: boolean, color: string, header: string, duration?: number) {
        let toastConfig: ToastOptions = {
            message: message,
            duration: duration || 1500,
            position: position,
            color: color,
            header: header,
            translucent: false,
            animated: true,
            buttons: [],
            cssClass: 'custom-toast',
        };

        if (acknowledgement) {
            delete toastConfig.duration;
            toastConfig.buttons.push(
                {
                    text: 'Dismiss',
                    role: 'cancel',
                    icon: 'close-circle'
                }
            );
        }



        const toast = await this.toastController.create(toastConfig);

        await toast.present();
        return toast;
    }

    async dismissToast(toast) {
        await toast.dismiss();
    }
}