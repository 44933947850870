import { UserAuthModel } from "../models/app.models";

export interface NotificationUpdate {
    id: number;
    flag: string;
}

export class AuthLogout {
    static readonly type = '[UI] Auth Logout';
    constructor() { }
}
export class AuthUser {
    static readonly type = '[UI] Auth User';
    constructor(public payload: UserAuthModel) { }
}

export class DeleteUser {
    static readonly type = '[UI] Delete User';
    constructor(public payload: boolean) { }
}

export class RegisterUnit {
    static readonly type = '[UI] Register Unit';
    constructor(public payload: string) { }
}

export class GetWalletId {
    static readonly type = '[UI] Get Wallet Id';
    constructor() { }
}

// export class GetDevices {
//     static readonly type = '[UI] Get Devices';
//     constructor() { }
// }
export class SetShowSpinner {
    static readonly type = '[UI] Show Spinner';
    constructor(public payload: boolean) { }
}

export class SetShowWalletSpinner {
    static readonly type = '[UI] Show WalletSpinner';
    constructor(public payload: boolean) { }
}

export class SetCameraActive {
    static readonly type = '[UI] Set Camera Active';
    constructor(public payload: boolean) { }
}
export class SetFailedAuth {
    static readonly type = '[UI] Failed Auth';
    constructor(public payload: boolean) { }
}

export class SetPage {
    static readonly type = '[UI] Set Page';
    constructor(public payload: any) { }
}


export class SetDeviceType {
    static readonly type = '[UI] Set DeviceType';
    constructor(public payload: string) { }
}

export class SetLoadDeviceGraph {
    static readonly type = '[UI] Set Load Device Graph';
    constructor(public payload: boolean) { }
}

export class GetDeviceGraph {
    static readonly type = '[UI] Get Device Graph';
    constructor(public payload: string) { }
}

export class LoadDashGraphs {
    static readonly type = '[UI] Load Dash Graphs';
    constructor(public payload: boolean) { }
}




export class LoadCurrentWalletBalance {
    static readonly type = '[UI] Load Current Wallet Balance';
}

export class getWallet {
    static readonly type = '[UI] Get Wallet';
}
export class WalletTopUp {
    static readonly type = '[UI] Wallet Top Up';
    constructor(public payload: any) { }
}
export class GetOzowHash {
    static readonly type = '[UI] Get OZOW Hash';
    constructor(public payload: string) { }
}

export class SetWalletTopupStatus {
    static readonly type = '[UI] Set Wallet Topup Status';
    constructor(public payload: string) { };
}

export class GetNotifications {
    static readonly type = '[UI] Get Notifications';
    constructor() { };
}

export class SetNotificationFlag {
    static readonly type = '[UI] Set Notification Flag';
    constructor(public payload: NotificationUpdate) { };
}

export class GetTransactionHistory {
    static readonly type = '[UI] Get Transaction History';
    constructor() { };
}


