import { DeviceModel } from "../models/deviceDetail.model";

export class SetCurrentDevice {
    static readonly type = '[DeviceDetail] Set Current Device';
    constructor(public payload: DeviceModel) { }
}

export class LoadDeviceDetail {
    static readonly type = '[DeviceDetail] Load Devices';
    constructor(public payload: DeviceModel[]) { }
}

export class UpdateDeviceDetail {
    static readonly type = '[DeviceDetail] Update Device';
    constructor(public payload: any) { }
}

export class CalcDetailHourlyData {
    static readonly type = '[DeviceDetail] Calc Hourly Data';
    constructor(public payload: any) { }

}

export class UpdateCurrentDay {
    static readonly type = '[DeviceDetail] Update Current Day';
    constructor(public payload: any) { }

}

export class UpdateReadings {
    static readonly type = '[DeviceDetail] Update Readings';
    constructor(public payload: any) { }

}