import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { DeviceModel } from '../state-management/models/devices.model';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    private apiUrl = 'https://api.netq.systems';
    private notificationUrl = 'https://api.netq.systems/alerts';
    private walletUrl = 'https://api.netq.systems/wallet';

    constructor(private _httpClient: HttpClient) { }


    deleteUser() {
        return this._httpClient.post(`${this.apiUrl}/deleteUser`, {}).toPromise();
    }

    getWalletId() {
        return this._httpClient.get(`${this.apiUrl}/getwalletidbysub`).toPromise();
    }


    getDevices() {
        let params = new HttpParams();

        params = params.append('viewUnitName', true);
        params = params.append('viewWalletId', true);
        params = params.append('viewDeviceId', true);
        params = params.append('viewMeterId', true);
        params = params.append('viewUserLocationsDefaultLocation', true);
        params = params.append('viewUserLocationsLocationName', true);
        params = params.append('viewLocationMetersId', true);
        params = params.append('viewLocationMetersAggregated', true);
        params = params.append('viewUnitId', true);
        params = params.append('viewWalletActive', true);
        params = params.append('viewLeak', true);
        params = params.append('viewValue', true);
        params = params.append('viewMeterType', true);
        params = params.append('viewMeterName', true);
        params = params.append('viewMetersDescription', true);
        params = params.append('viewMeterTypeDescription', true);
        params = params.append('viewMake', true);
        params = params.append('viewSettings', true);
        params = params.append('viewGraphType', true);
        params = params.append('viewUserLocationId', true);

        return this._httpClient.get(`${this.apiUrl}/querybuilder`, { params: params }).toPromise();
    };

    registerInitialLocation(token) {
        return this._httpClient.post(`${this.apiUrl}/writeuser`, token).toPromise();
    }


    getReadings(meterId) {
        let params = new HttpParams();

        // let meterId = 5422;
        let startDate = moment().startOf('month').subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss');
        let endDate = moment().format('YYYY-MM-DD HH:mm:ss');

        params = params.append('meterId', meterId);
        params = params.append('startDate', startDate);
        params = params.append('endDate', endDate);
        return this._httpClient.get(`${this.apiUrl}/readings`, { params: params }).toPromise();
    }

    getReadingsDateRange(meterId, startDate, endDate) {
        let checkdate = moment().format('YYYY-MM-DD');
        let startDateSend = moment(startDate).subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss');
        let endDateSend: any;
        if (endDate === checkdate) {
            endDateSend = moment().format('YYYY-MM-DD HH:mm:ss');
        } else {
            // endDateSend = moment(endDate).add(1, 'day').subtract(1, 'second').format('YYYY-MM-DD HH:mm:ss');
            endDateSend = moment(endDate).add(21, 'hour').format('YYYY-MM-DD HH:mm:ss');
        }
        let params = new HttpParams();
        params = params.append('meterId', meterId);
        params = params.append('startDate', startDateSend);
        params = params.append('endDate', endDateSend);
        return this._httpClient.get(`${this.apiUrl}/readings`, { params: params }).toPromise();
    }

    walletTopUp(paymentObj) {
        return this._httpClient.post(`${this.apiUrl}/makepayment`, paymentObj).toPromise();
    }

    getOzowHash(payload) {
        return this._httpClient.post(`${this.apiUrl}/generator`, payload).toPromise();
    }

    getCurrentWalletBalance(walletId) {
        let params = new HttpParams();
        //console.log('walletId :>> ', walletId);
        params = params.append('walletId', walletId);

        return this._httpClient.get(`${this.walletUrl}/checkfunds`, { params: params }).toPromise();
    }

    getWalletTransactions(walletId) {
        let params = new HttpParams();
        params = params.append('walletId', walletId);
        return this._httpClient.get(`${this.walletUrl}/wallethistorylimited`, { params: params }).toPromise();
    }

    getNotifications() {
        let params = new HttpParams();
        params = params.append('sub', '17fa7025-dc1f-4f7d-b461-64a8e4747fb3');
        return this._httpClient.get(`${this.notificationUrl}/usernotifications`, { params: params }).toPromise();
    }

    setNotificationFlag(id: number, flag: string) {
        let params = new HttpParams();
        params = params.append('id', id.toString());
        params = params.append('flag', flag);
        return this._httpClient.put(`${this.notificationUrl}/updatenotificationid`, {}, { params }).toPromise();
    }

    getSettings(id: number) {
        let params = new HttpParams();
        params = params.append('meterId', id.toString());
        return this._httpClient.get(`${this.apiUrl}/alertsettings`, { params }).toPromise();
    }

    setSetting(id: number, typeId: number, value: number, emailNotification: boolean) {
        let params = new HttpParams();
        params = params.append('Id', id);
        params = params.append('AlertTypeId', typeId);
        params = params.append('AlertValue', value);
        params = params.append('EmailNotification', emailNotification);
        return this._httpClient.put(`${this.apiUrl}/alertsettings`, {}, { params }).toPromise();
    }

    createSetting(typeId: number, value: number, emailNotification: boolean, userLocationId: number, meterId: number, active: boolean) {
        let params = new HttpParams();
        params = params.append('AlertTypeId', typeId);
        params = params.append('AlertValue', value);
        params = params.append('EmailNotification', emailNotification);
        params = params.append('UserLocationId', userLocationId);
        params = params.append('MeterId', meterId);
        params = params.append('Active', active);
        return this._httpClient.post(`${this.apiUrl}/alertsettings`, {}, { params }).toPromise();
    }
}

